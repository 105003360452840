<template>
  <!--平台首页-->
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <!--  统计-->
      <div class="contentshouye2">
        <Row :gutter="16" style="margin-left: -12px; margin-right: -12px;">
          <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
            <div class="content_kshouye2">
              <img class="content_kshouye2_img" src="@/assets/images/home/icon/icon1.png" alt="">
              <div class="content_kshouye2_div">
                <div class="content_kshouye2_div_f1">
                  <span style="vertical-align: middle;">平台企业总数</span>
                  <!--<div class="wenhaoimg"></div>-->
                </div>
                <div class="content_kshouye2_div_f2">
                  <div>
                    {{header_statics.firm_count.total}}
                  </div>
                  <div class="content_kshouye2_div_f2_f">
                    <div>
                      较前一日
                      <span>
                        <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">
                        <span style="color: #ec554d;"> {{header_statics.firm_count.yesterday}}</span>
                      </span>
                    </div>
                    <div>
                      较上一周
                      <span>
                        <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">
                        <span style="color: #49d2bc;">{{header_statics.firm_count.last_week}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
            <div class="content_kshouye2">
              <img class="content_kshouye2_img" src="@/assets/images/home/icon/icon2.png" alt="">
              <div class="content_kshouye2_div">
                <div class="content_kshouye2_div_f1">
                  <span style="vertical-align: middle;">订单总数</span>
                  <!--<div class="wenhaoimg"></div>-->
                </div>
                <div class="content_kshouye2_div_f2">
                  <div>
                    {{header_statics.order_count.total}}
                  </div>
                  <div class="content_kshouye2_div_f2_f">
                    <div>
                      较前一日
                      <span>
                        <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">
                        <span style="color: #ec554d;">{{header_statics.order_count.yesterday}}</span>
                      </span>
                    </div>
                    <div>
                      较上一周
                      <span>
                        <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">
                        <span style="color: #49d2bc;">{{header_statics.order_count.last_week}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <!--<Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
          <!--  <div class="content_kshouye2">-->
          <!--    <img class="content_kshouye2_img" src="@/assets/images/home/icon/icon3.png" alt="">-->
          <!--    <div class="content_kshouye2_div">-->
          <!--      <div class="content_kshouye2_div_f1">-->
          <!--        <span style="vertical-align: middle;">累计投融资总额</span>-->
          <!--        <div class="wenhaoimg"></div>-->
          <!--      </div>-->
          <!--      <div class="content_kshouye2_div_f2">-->
          <!--        <div>-->
          <!--          1000-->
          <!--        </div>-->
          <!--        <div class="content_kshouye2_div_f2_f">-->
          <!--          <div>-->
          <!--            较前一日-->
          <!--            <span>-->
          <!--              <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">-->
          <!--              <span style="color: #ec554d;">3%</span>-->
          <!--            </span>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            较上一周-->
          <!--            <span>-->
          <!--              <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">-->
          <!--              <span style="color: #49d2bc;">3%</span>-->
          <!--            </span>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</Col>-->
          <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
            <div class="content_kshouye2">
              <img class="content_kshouye2_img" src="@/assets/images/home/icon/icon4.png" alt="">
              <div class="content_kshouye2_div">
                <div class="content_kshouye2_div_f1">
                  <span style="vertical-align: middle;">累计订单数</span>
                  <!--<div class="wenhaoimg"></div>-->
                </div>
                <div class="content_kshouye2_div_f2">
                  <div>
                    {{header_statics.cumulative_order_count.total}}
                  </div>
                  <div class="content_kshouye2_div_f2_f">
                    <div>
                      较前一日
                      <span>
                        <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">
                        <span style="color: #ec554d;">{{header_statics.cumulative_order_count.yesterday}}</span>
                      </span>
                    </div>
                    <div>
                      较上一周
                      <span>
                        <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">
                        <span style="color: #49d2bc;">{{header_statics.cumulative_order_count.last_week}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <!-- 订单数据总览  企业数据总览 -->
      <div class="Overview">
        <Row :gutter="16">
          <Col span="12">
            <div class="Overview_t">
              <div class="Overview_t_icon"></div>
              <div class="Overview_t_text">
                订单数据总览
              </div>
            </div>
            <div class="Overview_c">
              <Row justify="space-around">
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">本年度总订单额</div>
                    <div class="Overview_c_k_f2">{{order_data_statics.TotalMoneyYear}}</div>
                  </div>
                </Col>
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">本年度总订单额</div>
                    <div class="Overview_c_k_f2">{{order_data_statics.TotalCountYear}}</div>
                  </div>
                </Col>
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">本年度总订单额</div>
                    <div class="Overview_c_k_f2">{{order_data_statics.TotalSettledMoneyYear}}</div>
                  </div>
                </Col>
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">本年度总订单额</div>
                    <div class="Overview_c_k_f2">{{order_data_statics.TotalSettledCountYear}}</div>
                  </div>
                </Col>
              </Row>
              <!--<div class="Overview_c_k">-->
              <!--  <div class="Overview_c_k_f1">本年度总订单额</div>-->
              <!--  <div class="Overview_c_k_f2">1000</div>-->
              <!--</div>-->
              <!--<div class="Overview_c_k">-->
              <!--  <div class="Overview_c_k_f1">本年度总订单额</div>-->
              <!--  <div class="Overview_c_k_f2">1000</div>-->
              <!--</div>-->
              <!--<div class="Overview_c_k">-->
              <!--  <div class="Overview_c_k_f1">本年度总订单额</div>-->
              <!--  <div class="Overview_c_k_f2">1000</div>-->
              <!--</div>-->
              <!--<div class="Overview_c_k">-->
              <!--  <div class="Overview_c_k_f1">本年度总订单额</div>-->
              <!--  <div class="Overview_c_k_f2">1000</div>-->
              <!--</div>-->
            </div>
          </Col>
          <Col span="12">
            <div class="Overview_t">
              <div class="Overview_t_icon"></div>
              <div class="Overview_t_text">
                企业数据总览
              </div>
            </div>
            <div class="Overview_c">
              <Row justify="space-around">
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">用户总数</div>
                    <div class="Overview_c_k_f2">{{firm_data_statics.UsersCount}}</div>
                  </div>
                </Col>
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">客户总数 </div>
                    <div class="Overview_c_k_f2">{{firm_data_statics.ChannelCount}}</div>
                  </div>
                </Col>
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">服务商总数</div>
                    <div class="Overview_c_k_f2">{{firm_data_statics.FacilitatorCount}}</div>
                  </div>
                </Col>
                <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                  <div class="Overview_c_k">
                    <div class="Overview_c_k_f1">共享运力总数</div>
                    <div class="Overview_c_k_f2">{{firm_data_statics.ShareDriverCount}}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

    </Card>

    <!--  常用功能入口-->
    <Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;">
      <div class="Commonfunctions">
        <div class="Commonfunctions_t">
          <div class="Commonfunctions_t_icon"></div>
          <div class="Commonfunctions_t_text">
            常用功能入口
          </div>
        </div>
        <div class="Commonfunctions_c">
          <grid-menu />
        </div>
      </div>

    </Card>

    <!--  订单数量汇总 -->
    <Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;">
      <div class="order">
        <div class="order_t">
          <div style="display: flex; align-items: center;">
            <div class="order_t_icon"></div>
            <div class="order_t_text">
              <span>订单数量汇总</span>
            </div>
            <div class="anniu">
              <div v-for="(item,index) in tab" :key="index" class="div" :class="{div1:index==isShow}" @click='change(item,index)'>
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="order_c">
          <Row justify="space-around">
            <Col v-bind="ordergrid_l" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
              <div class="order_img">
                <div class="order_img_t">昨日订单数</div>
                <div class="order_img_s">{{order_count_charts.last_week}}</div>
              </div>
            </Col>
            <Col v-bind="ordergrid_r" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
              <echarts-from
                ref="userChart"
                :echartsTitle="line"
                :infoList="infoList"
                :series="series"
                v-if="infoList"
              ></echarts-from>
            </Col>
          </Row>
        </div>
      </div>
    </Card>

    <!--  订单金额统计 -->
    <Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;">
      <div class="order">
        <div class="order_t">
          <div style="display: flex; align-items: center;">
            <div class="order_t_icon"></div>
            <div class="order_t_text">
              <span>订单金额统计</span>
            </div>
            <div class="anniu">
              <div v-for="(item,index) in moneytab" :key="index" class="div" :class="{moneydiv:index==moneyisShow}" @click='moneychange(item,index)'>
                {{item.name}}
              </div>
            </div>
          </div>
          <!--<div class="datexuanzeqi">-->
          <!--  <DatePicker :value="value2" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"></DatePicker>-->
          <!--</div>-->
        </div>
        <div class="order_c">
          <Row justify="space-around">
            <Col v-bind="ordergrid_l" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
              <div class="order_img1">
                <div class="order_img_t1">昨日订单金额</div>
                <div class="order_img_s1">{{order_money_statics.last_week}}</div>
              </div>
            </Col>
            <Col v-bind="ordergrid_r" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
              <echarts-fr
                ref="userChart"
                :echartsTitle="line"
                :infoListse="infoListse"
                :series="series1"
                v-if="infoList"></echarts-fr>
            </Col>
          </Row>
        </div>
      </div>
    </Card>

  </div>
</template>

<script>
//常用功能入口
import gridMenu from './components/gridMenu';
// 订单数量echarts
import echartsFrom from '@/components/echarts/index';
// 订单金额
import echartsFr from '@/components/echarts/moneyindex';
//  引入的接口
import {
  firm_data_statics,
  getheader_statics,
  order_count_charts,
  order_data_statics,
  order_money_statics
} from "@/api/statistics";

export default {
  name: 'home_index',
  data () {
    return {
      // 头部统计
      header_statics:{},
      //订单数据总览
      order_data_statics:{},
      //企业数据总览
      firm_data_statics:{},
      //订单数据总览
      order_count_charts:{},
      //订单金额汇总
      order_money_statics:{},

      grid: {
        xl: 6,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },
      grid1:{
        xl: 6,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },

      ordergrid_l:{
        xl: 4,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },
      ordergrid_r:{
        xl: 20,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },

      //tab
      isShow:1,
      tab:[
        {
          name:'今日',
          value:'today'
        },
        {
          name:'本周',
          value:'week'
        },
        {
          name:'本月',
          value:'month'
        }
      ],
      cycle:'week',


      moneyisShow:1,
      moneytab:[
        {
          name:'今日',
          value:'today'
        },
        {
          name:'本周',
          value:'week'
        },
        {
          name:'本月',
          value:'month'
        }
      ],
      cycle1:'week',

      //echarts 订单数量
      line: 'line',
      infoList: {
        xAxis: [
          // "07-9",
          // "07-10",
          // "07-11",
          // "07-12",
          // "07-13",
          // "07-14",
        ],
        series: [
          // 18,
          // 39,
          // 60,
          // 48,
          // 40,
          // 46
        ]
      },
      series: [],

      // 订单金额统计
      infoListse:{
        xAxis: [
          // "07-9",
          // "07-10",
          // "07-11",
          // "07-12",
          // "07-13",
          // "07-14",
        ],
        series: [
          // 18,
          // 39,
          // 60,
          // 48,
          // 40,
          // 46
        ]
      },
      series1:[],

    }
  },
  components: {
    //常用功能入口
    gridMenu,
    //订单数量echarts
    echartsFrom,
    //订单金额echarts
    echartsFr
  },
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
    // this.getStatistics();

    this.getgetheader_statics();
    this.getorder_data_statics();
    this.getfirm_data_statics();
    this.getorder_count_charts();
    this.getorder_money_statics();
  },
  // 方法集合
  methods: {
    // 点击tab
    change(item,index){
      console.log(item)
      this.isShow = index;
      this.cycle = item.value;
      this.getorder_count_charts();
    },
    moneychange(item,index){
      console.log(item)
      this.moneyisShow = index;
      this.cycle1 = item.value;
      this.getorder_money_statics();
    },

    //获取头部统计数据接口
    getgetheader_statics(){
      getheader_statics().then(res => {
        this.header_statics = res.data;
      })
    },

    //获取订单数据总览
    getorder_data_statics(){
      order_data_statics().then(res => {
        this.order_data_statics = res.data;
      })
    },

    //获取企业数据总览
    getfirm_data_statics(){
      firm_data_statics().then(res => {
        this.firm_data_statics = res.data;
      })
    },

    //获取订单数据量汇总
    getorder_count_charts(){
      order_count_charts({
        cycle: this.cycle
      }).then(res => {
        // console.log(res)
        this.order_count_charts = res.data;
        this.infoList.xAxis = this.order_count_charts.echartsData.xAxis;
        //模拟数据
        let ser = this.order_count_charts.echartsData.series;
        this.series = [
          {
            data: ser,
            name: '订单数',
            type: 'line',
            tooltip: true,
            smooth: true,
            symbol: 'none',
            areaStyle: {
              normal: {
                opacity: 0.2,
              },
            },
          },
        ];
      })
    },

    //获取订单金额汇总
    getorder_money_statics(){
      order_money_statics({
        cycle: this.cycle1
      }).then(res => {
        console.log(res)
        this.order_money_statics = res.data;
        this.infoListse.xAxis = this.order_money_statics.echartsData.xAxis;
        //模拟数据
        let ser = this.order_money_statics.echartsData.series;
        this.series1 = [
          {
            data: ser,
            name: '金额',
            type: 'line',
            tooltip: true,
            smooth: true,
            symbol: 'none',
            areaStyle: {
              normal: {
                opacity: 0.2,
              },
            },
          },
        ];
      })
    },



    // 统计示例
    getStatistics() {
      //模拟数据
      let ser = [
        0,
        18,
        1,
        39,
        2,
        60,
        3
      ]
      this.series = [
        {
          data: ser,
          name: '订单数',
          type: 'line',
          tooltip: true,
          smooth: true,
          symbol: 'none',
          areaStyle: {
            normal: {
              opacity: 0.2,
            },
          },
        },
      ];
    },

    // 监听页面宽度变化，刷新表格
    handleResize() {
      if (this.infoList && this.series.length !== 0) this.$refs.userChart.handleResize();
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
  .title{
    font-size: 24px;
    font-weight: 500;
    color: #707070;
    margin-bottom: 20px;
  }
  .contentshouye2{
    width: 100%;
  }
  // 解决高度坍塌
  .content_kshouye2:after{
    content: '';
    clear: both;
    display: block;
  }
  .content_kshouye2{
    //鼠标变小手
    cursor: pointer;
    width: 100%;
    padding: 15px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(230,230,230,0.88);
    .content_kshouye2_img{
      width: 45px;
      height: 41px;
      float: left;
      vertical-align: middle;
      margin-top: 10px;
    }
    .content_kshouye2_div{
      //width: 80%;
      float: left;
      vertical-align: middle;
      margin-left: 18px;
    }
    .content_kshouye2_div_f1{
      font-weight: 500;
      text-align: left;
      color: #3f4155;
    }
    .wenhaoimg{
      display: inline-block;
      vertical-align: middle;
      width: 13px;
      height: 13px;
      margin-left: 15px;
      background-image: url("../../assets/images/home/icon/wenhao.png");
    }
    .content_kshouye2_div_f2{
      font-size: 30px;
      font-weight: 700;
      text-align: left;
      color: #3f4155;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .content_kshouye2_div_f2_f{
      font-size: 12px;
      font-weight: 400;
      color: #69726e;
      margin-left: 30px;
    }
  }
  .content_kshouye2:hover{
    background: #fff7ee;
    border: 1px solid #ff9819;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(255,152,25,0.25);
  }
  .content_kshouye2:hover .wenhaoimg{
    background-image: url("../../assets/images/home/icon/wenhao_active.png");
  }

  //总览样式
  .Overview{
    margin-top: 40px;
    .Overview_t{
      display: flex;
      align-items: center;
    }
    .Overview_t_icon{
      width: 5px;
      height: 20px;
      background: #ff9b05;
      display: inline-block;
    }
    .Overview_t_text{
      font-size: 18px;
      font-weight: 700;
      color: #202033;
      display: inline-block;
      margin-left: 10px;
    }
    .Overview_c{
      width: 100%;
      padding: 30px 20px 30px 20px;
      border: 1px solid #e9eaf1;
      margin-top: 20px;
    }
    .Overview_c_k{
      //width: 25%;
      text-align: center;
      //display: inline-block;
    }
    .Overview_c_k_f1{
      font-size: 14px;
      font-weight: 400;
      color: #868792;
    }
    .Overview_c_k_f2{
      font-size: 30px;
      font-weight: 700;
      color: #3f4155;
    }
  }

  // 常用功能入口
  .Commonfunctions{

    .Commonfunctions_t{
      display: flex;
      align-items: center;
    }
    .Commonfunctions_t_icon{
      width: 5px;
      height: 20px;
      background: #ff9b05;
      display: inline-block;
    }
    .Commonfunctions_t_text{
      font-size: 18px;
      font-weight: 700;
      color: #202033;
      display: inline-block;
      margin-left: 10px;
    }
    .Commonfunctions_c{
      width: 100%;
      //padding: 30px 20px 30px 20px;
      //border: 1px solid #e9eaf1;
      margin-top: 20px;
    }

  }

  // 常用功能入口
  .order{
    .order_t{
      display: flex;
      align-items: center;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
    }
    .order_t_icon{
      width: 5px;
      height: 20px;
      background: #ff9b05;
      display: inline-block;
    }
    .order_t_text{
      font-size: 18px;
      font-weight: 700;
      color: #202033;
      display: inline-block;
      margin-left: 10px;
    }
    .order_c{
      width: 100%;
      //padding: 30px 20px 30px 20px;
      //border: 1px solid #e9eaf1;
      margin-top: 20px;
    }

  }
  .anniu{
    //鼠标变小手
    cursor: pointer;
    margin-left: 20px;
    display: inline-block;
    .div{
      width: 60px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #3f4155;
      border: 1px solid #c5c6cc;
    }
    .div:hover{
      background: #3f4155;
      color: #FFFFFF;
      border: 1px solid #3f4155;
    }
    // css选择器 第一个
    .div:first-child{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px
    }
    //最后一个
    .div:last-child{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px
    }
    .div1{
      background: #3f4155;
      color: #FFFFFF;
      border: 1px solid #3f4155;
    }
    .moneydiv{
      background: #3f4155;
      color: #FFFFFF;
      border: 1px solid #3f4155;
    }
  }
  .order_img{
    width: 220px;
    height: 200px;
    background-image: url("../../assets/images/home/shuliang.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 50px;
    padding-left: 30px;
    margin: auto;
    margin-top: 45px;
    .order_img_t{
      font-size: 16px;
      font-weight: 500;
      color: #868792;
    }
    .order_img_s{
      font-size: 36px;
      font-weight: 700;
      color: #5976e1;
    }
  }
  .order_img1{
    width: 220px;
    height: 200px;
    background-image: url("../../assets/images/home/jine.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 50px;
    padding-left: 30px;
    margin: auto;
    margin-top: 45px;
    .order_img_t1{
      font-size: 16px;
      font-weight: 500;
      color: #868792;
    }
    .order_img_s1{
      font-size: 36px;
      font-weight: 700;
      color: #FF9B05;
    }
  }
</style>
